import {
  Description as ToastDescription,
  Provider as ToastProviderRx,
  Root as ToastRoot,
  Viewport as ToastViewport,
} from '@radix-ui/react-toast';
import { ReactNode } from 'react';

import { SvgIcon } from 'src/components/mainComponents/SvgIcon';

import './Toast.scss';
import type { ToastProps } from './useToast';

export function Toast(props: ToastProps) {
  return (
    <ToastRoot duration={3000} className='Toast' open={props.open} onOpenChange={props.onOpenChange}>
      <ToastDescription>
        {props.type === 'success' && <SvgIcon color='primary' size='xl' name='checkmark--circle' />}
        {props.message}
      </ToastDescription>
    </ToastRoot>
  );
}

/**
 *
 * @todo Merge `ToastProvider` and `NotificationProvider`.
 * The latter has its own way of handling notifications. It also shows notifications at the bottom-right.
 */
export function ToastProvider(props: { children: ReactNode }) {
  return (
    <ToastProviderRx>
      {props.children}
      <ToastViewport className='Toast__viewport' />
    </ToastProviderRx>
  );
}
